import React, { useState, useEffect, useRef } from "react";
import {
    Upload,
    Play,
    Loader,
    CheckCircle,
    Volume2,
    Info,
    RefreshCw,
    Edit2,
} from "lucide-react";
import "./GolfAIAnalysis.css";
import axios from "axios";

interface Score {
    name: string;
    date: string;
    scores: number[];
}

interface UploadedFile {
    name: string;
    size: string;
}

// HexGraph 컴포넌트는 그대로 유지됩니다.
const HexGraph: React.FC<{ uScores: number[]; aScores: number[] }> = ({
    uScores,
    aScores,
}) => {
    const cats = [
        "어드레스",
        "백스윙",
        "탑",
        "다운스윙",
        "임팩트",
        "팔로우스루",
    ];
    const mScore = 100,
        size = 300,
        center = size / 2,
        radius = size * 0.4;
    const getPoints = (scores: number[]) =>
        cats
            .map((_, i) => {
                const a = (Math.PI * 2 * i) / cats.length - Math.PI / 2;
                const s = scores[i] / mScore;
                return `${center + radius * s * Math.cos(a)},${
                    center + radius * s * Math.sin(a)
                }`;
            })
            .join(" ");
    const getAxis = (i: number) => {
        const a = (Math.PI * 2 * i) / cats.length - Math.PI / 2;
        return {
            x: center + radius * Math.cos(a),
            y: center + radius * Math.sin(a),
        };
    };
    return (
        <svg
            viewBox={`0 0 ${size} ${size}`}
            style={{ width: "100%", height: "auto" }}
        >
            <polygon
                points={getPoints([
                    mScore,
                    mScore,
                    mScore,
                    mScore,
                    mScore,
                    mScore,
                ])}
                fill="rgba(200,200,200,0.3)"
                stroke="#ccc"
            />
            {cats.map((_, i) => {
                const { x, y } = getAxis(i);
                return (
                    <line
                        key={i}
                        x1={center}
                        y1={center}
                        x2={x}
                        y2={y}
                        stroke="#ccc"
                        strokeDasharray="5,5"
                    />
                );
            })}
            <polygon
                points={getPoints(aScores)}
                fill="rgba(255,165,0,0.5)"
                stroke="orange"
            />
            <polygon
                points={getPoints(uScores)}
                fill="rgba(0,128,255,0.5)"
                stroke="blue"
            />
            {cats.map((c, i) => {
                const { x, y } = getAxis(i);
                console.log(x, y);
                const off = 20;
                const lx =
                    center +
                    (radius + off) *
                        Math.cos((Math.PI * 2 * i) / cats.length - Math.PI / 2);
                const ly =
                    center +
                    (radius + off) *
                        Math.sin((Math.PI * 2 * i) / cats.length - Math.PI / 2);
                return (
                    <text
                        key={i}
                        x={lx}
                        y={ly}
                        textAnchor="middle"
                        dominantBaseline="middle"
                        fontSize="12"
                        fill="#333"
                    >
                        {c}
                    </text>
                );
            })}
            {uScores.map((s, i) => {
                const { x, y } = getAxis(i);
                console.log(x, y);
                const sx =
                    center +
                    radius *
                        0.8 *
                        (s / mScore) *
                        Math.cos((Math.PI * 2 * i) / cats.length - Math.PI / 2);
                const sy =
                    center +
                    radius *
                        0.8 *
                        (s / mScore) *
                        Math.sin((Math.PI * 2 * i) / cats.length - Math.PI / 2);
                return (
                    <text
                        key={i}
                        x={sx}
                        y={sy}
                        textAnchor="middle"
                        dominantBaseline="middle"
                        fontSize="10"
                        fill="blue"
                    >
                        {s}
                    </text>
                );
            })}
            <rect
                x="10"
                y="10"
                width="15"
                height="15"
                fill="rgba(0,128,255,0.5)"
                stroke="blue"
            />
            <text x="30" y="22" fontSize="12" fill="#333">
                사용자 점수
            </text>
            <rect
                x="10"
                y="30"
                width="15"
                height="15"
                fill="rgba(255,165,0,0.5)"
                stroke="orange"
            />
            <text x="30" y="42" fontSize="12" fill="#333">
                평균 점수
            </text>
        </svg>
    );
};

const SwingAnalysis: React.FC<{ uScores: number[]; aScores: number[] }> = ({
    uScores,
    aScores,
}) => (
    <div className="card">
        <div className="card-header">
            <h3 className="card-title">스윙 분석 점수</h3>
        </div>
        <div className="card-content">
            <HexGraph uScores={uScores} aScores={aScores} />
        </div>
    </div>
);

const GolfAIAnalysis: React.FC = () => {
    const [isAnalyzing, setIsAnalyzing] = useState<boolean>(false);
    const [analysisComplete, setAnalysisComplete] = useState<boolean>(false);
    const [uploadedFile, setUploadedFile] = useState<UploadedFile | null>(null);
    // const [isPlaying, setIsPlaying] = useState<boolean>(false);
    const [progress, setProgress] = useState<number>(0);
    // const [lang, setLang] = useState<string>('ko');
    const [history, setHistory] = useState<Score[]>([]);
    const [uScores, setUScores] = useState<number[]>([80, 87, 76, 80, 87, 73]);
    const [aScores] = useState<number[]>([70, 72, 75, 73, 68, 78]);
    const [showHistory, setShowHistory] = useState<boolean>(false); // 추가된 부분
    const [videoSrc, setVideoSrc] = useState<string | null>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [currentAnalysisName, setCurrentAnalysisName] = useState<string>("");
    const [editingHistoryIndex, setEditingHistoryIndex] = useState<
        number | null
    >(null);
    const [analysisStarted, setAnalysisStarted] = useState<boolean>(false);
    // const [apiResponse] = useState<string | null>(null);
    const [transcript, setTranscript] = useState<string | null>(null);
    console.log(transcript);
    const [audioBlob, setAudioBlob] = useState<Blob | null>(null);
    const audioRef = useRef<HTMLAudioElement>(null);

    const resetUpload = () => {
        setUploadedFile(null);
        setVideoSrc(null);
        setIsAnalyzing(false);
        setAnalysisComplete(false);
        setProgress(0);
    };

    const handleUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            if (file.type.startsWith("video/")) {
                const fileURL = URL.createObjectURL(file);
                setVideoSrc(fileURL);
                setUploadedFile({
                    name: file.name,
                    size: `${(file.size / (1024 * 1024)).toFixed(2)} MB`,
                });
                setProgress(0);
                setIsAnalyzing(true);
                setAnalysisComplete(false);

                try {
                    const prompt = `너는 박상준 아나운서야.
          골프 스윙 영상을 보고 분석해줘. 분석결과는 직접 말하는 내용의 대본으로 나와야해. 
          예시는 다음과 같아. 
          [예시 1]
          지금 영상은 연습장에서 이제 연습하는 장면인데요 테이크오이 올라가는 순간에 약간 좀 손으로 사용을 해가지고 손백스윙을 만들려는 경향이 약간 좀 있는 것 같아요 약간 좀 변경이 돼야 될 것 같고 하지만 올라갈 때 탄모양에서 손목모양은 나쁘지 않아요 그런데 약간의 치키닝 완전은 하진 않지만 완전 치키닝이 아니라 반 치키닝 오른쪽 팔이 약간 좀 들리는 현상이 좀 생겨요 근데 내려올 때도 나쁘진 않아요 그리고 임팩트 순간에서 체를 던지는 것도 나쁘지 않은데 체를 던지는 것까진 좋은데 지금 상황에서 더 좋은 거는 왼쪽 골반이 조금 더 회전이 되면서 열리면서 잘 빠져주면은 파워도 실리고 왼발을 좀 디디면서 그렇게 하면서 좀 간결한 또 나중에 폴로스로우와 피니시가 된다 그러면은 상당히 좋은 스윙이지 않을까 생각이 듭니다.
          [예시 2]
          이번 영상은 스윙 장면인데요 아연입니다 어 보니까 테이크웨이 때 손모로 약간 좀 백스윙이 올라가는 모양이 있는데 그런데 그것 치고는 손모양이 나쁘진 않은 것 같아요 그런데 마지막에 탑에서 내려오기 전에 오른팔이 약간 치키닝이 되네요 아 그러니까 겨드랑이에서 많이 떨어진다는 얘기죠 어 그리고 그렇지만 축은 좀 잘 지켜지는 것 같아요 그래도 공을 끝까지 잘 보는 거 있는 것 같고요 그리고 명치 쪽에 클럽이 앞쪽에서 다니는 것은 나쁘지는 않은 것 같습니다 그리고 임팩트 이후에 한 가지 조금 아쉬운 거는 골반 회전이 조금 없다라는 거 그러다 보니까 약간 좀 거리선에도 볼 수 있고 어쩌면 하체에 대한 턴이 조금은 부족하지 않았나 싶고요 그리고 하이 피니쉬를 이루어지는데 조금 더 간결하게 왼쪽 어깨 쪽으로 스윙이 조금 더 돌아가는 스윙이 된다 그러면은 조금 더 멋지고 그리고 간결한 스윙이 되지 않을까 생각이 듭니다
          
          영상과 함께 보내주면 해당 영상을 예시처럼 분석해줘.
          일단 제일 먼저 자기소개를 해줘야해.
          `;
                    const formData = new FormData();
                    formData.append("file", file);
                    formData.append("prompt", prompt);

                    // 영상 분석 요청
                    setProgress(20);
                    await new Promise((resolve) => setTimeout(resolve, 1000));
                    setProgress(30);
                    await new Promise((resolve) => setTimeout(resolve, 1000));
                    setProgress(40);
                    const response = await axios.post(
                        "https://yt-gpt-utils.illusionists1004.workers.dev/api/gemini/vision/transcript",
                        formData,
                        {
                            headers: {
                                "Content-Type": "multipart/form-data",
                            },
                        }
                    );

                    if (response.status === 200) {
                        setProgress(50);
                        // let transcript = "안녕하세요 박상준 아나운서입니다.";
                        // let transcript = "";
                        // transcript += response.data.transcript;
                        setTranscript(response.data.transcript);

                        // 음성 분석 요청
                        setProgress(60);
                        await getVoiceAnalysis(response.data.transcript);

                        // 새로운 분석 결과 생성 및 저장
                        const newScores = Array(6)
                            .fill(0)
                            .map(() => Math.floor(Math.random() * 30) + 70);
                        setUScores(newScores);

                        const newAnalysis = {
                            name:
                                currentAnalysisName ||
                                `분석 ${history.length + 1}`,
                            date: new Date().toLocaleString(),
                            scores: newScores,
                        };
                        setHistory((prev) => [...prev, newAnalysis]);

                        setProgress(100);
                        setIsAnalyzing(false);
                        setAnalysisComplete(true);
                        setCurrentAnalysisName("");
                    } else {
                        throw new Error("분석 요청 실패");
                    }
                } catch (error) {
                    console.error("영상 분석 중 오류 발생:", error);
                    alert("영상 분석 중 오류가 발생했습니다.");
                    setIsAnalyzing(false);
                }
            } else {
                alert("Please upload a video file.");
            }
        }
    };

    const startAnalysis = () => {
        if (!analysisStarted) {
            setIsAnalyzing(true);
            setAnalysisStarted(true);
        }
    };

    useEffect(() => {
        let timer: NodeJS.Timeout;
        if (isAnalyzing && analysisStarted) {
            timer = setInterval(() => {
                setProgress((old) => {
                    const newP = old + 10;
                    if (newP === 100) {
                        clearInterval(timer);
                        setIsAnalyzing(false);
                        setAnalysisComplete(true);
                        setAnalysisStarted(false);
                        const newScores = Array(6)
                            .fill(0)
                            .map(() => Math.floor(Math.random() * 30) + 70);
                        setUScores(newScores);

                        const newAnalysis = {
                            name:
                                currentAnalysisName ||
                                `분석 ${history.length + 1}`,
                            date: new Date().toLocaleString(),
                            scores: newScores,
                        };
                        setHistory((prev) => [...prev, newAnalysis]);
                        setCurrentAnalysisName("");
                    }
                    return newP;
                });
            }, 500);
        }
        return () => clearInterval(timer);
    }, [isAnalyzing, analysisStarted, currentAnalysisName, history.length]);

    const triggerFileInput = () => {
        fileInputRef.current?.click();
    };

    // const handlePlay = () => setIsPlaying(!isPlaying);

    const renderResult = () => (
        <div className="result-container">
            <SwingAnalysis uScores={uScores} aScores={aScores} />
            <div className="card advice-card">
                <div className="card-header">
                    <h3 className="card-title">자세 교정 조언</h3>
                </div>
                <div className="card-content">
                    <p>
                        1. 백스윙: 어깨 회전이 다소 부족합니다. 왼쪽 어깨를 더
                        아래로 내리고 오른쪽 어깨를 위로 올리는 느낌으로
                        회전해보세요.
                        <br />
                        2. 다운스윙: 하체 사용이 적습니다. 체중 이동 더
                        적극적으로 하여 파워를 증가시킬 수 있습니다.
                        <br />
                        3. 임팩트: 공을 치는 순간 왼팔이 약간 구부러집니다.
                        임팩트 시 왼팔을 더 곧게 유지해보세요.
                        <br />
                        4. 피니쉬: 체중이 오른발에 남아있습니다. 스윙 후 왼발로
                        체중을 완전히 이동하여 균형을 유지하세요.
                    </p>
                </div>
            </div>
        </div>
    );

    const handleEditHistoryName = (index: number, newName: string) => {
        setHistory((prev) =>
            prev.map((item, i) =>
                i === index ? { ...item, name: newName } : item
            )
        );
        setEditingHistoryIndex(null);
    };

    const renderHistory = () => (
        <div className="history-container">
            <h3>분석 기록</h3>
            {history.map((item, index) => (
                <div key={index} className="history-item">
                    {editingHistoryIndex === index ? (
                        <div className="edit-name-input">
                            <input
                                type="text"
                                value={item.name}
                                onChange={(e) =>
                                    handleEditHistoryName(index, e.target.value)
                                }
                                onBlur={() => setEditingHistoryIndex(null)}
                                autoFocus
                            />
                        </div>
                    ) : (
                        <h4>
                            {item.name}
                            <button
                                className="edit-name-button"
                                onClick={() => setEditingHistoryIndex(index)}
                            >
                                <Edit2 size={16} />
                            </button>
                        </h4>
                    )}
                    <p>날짜: {item.date}</p>
                    <p>
                        점수:
                        {item.scores.map((score, i) => (
                            <span key={i}>
                                {getCategoryName(i)}: {score}
                                {i !== 5 ? ", " : ""}
                            </span>
                        ))}
                    </p>
                </div>
            ))}
        </div>
    );

    const getCategoryName = (index: number): string => {
        const categories = [
            "어드레스",
            "백스윙",
            "탑",
            "다운스윙",
            "임팩트",
            "팔로우스루",
        ];
        return categories[index];
    };

    const getVoiceAnalysis = async (transcriptText: string) => {
        try {
            setProgress(75);
            const response = await axios.post(
                "https://yt-gpt-utils.illusionists1004.workers.dev/api/elevenlabs/voice",
                {
                    voice_nickname: "sangjun",
                    prompt: transcriptText,
                },
                {
                    responseType: "blob",
                }
            );
            console.log(response.data);
            // console.log(response.data.blob());
            setAudioBlob(response.data);
        } catch (error) {
            console.error("음성 생성 중 오류 발생:", error);
            alert("음성 생성 중 오류가 발생했습니다.");
        }
    };

    const playAudio = () => {
        if (audioBlob && audioRef.current) {
            const audioUrl = URL.createObjectURL(audioBlob);
            const audio = new Audio(audioUrl);
            audio.play();
            audioRef.current.src = audioUrl;
            audioRef.current.play();
        }
    };

    return (
        <div className="golf-ai-analysis">
            <header className="header">
                <div className="container">
                    <h1 className="title">박상준의 진짜골프 AI 분석</h1>
                </div>
            </header>
            <main className="container main-content">
                <div className="upload-section">
                    <div className="card">
                        <div className="card-header">
                            <h2 className="card-title">
                                <Upload className="icon" />
                                영상 업로드
                            </h2>
                            <div className="card-actions">
                                {uploadedFile &&
                                    !isAnalyzing &&
                                    !analysisComplete && (
                                        <button
                                            onClick={startAnalysis}
                                            className="card-button"
                                        >
                                            분석 시작
                                        </button>
                                    )}
                            </div>
                        </div>
                        {isAnalyzing && (
                            <div className="alert">
                                <Loader className="icon spinning" />
                                <h3 className="alert-title">분석 중...</h3>
                                <p className="alert-description">
                                    AI가 귀하의 골프 자세를 분석하고 있습니다.
                                    잠시만 기다려주세요.
                                </p>
                                <div className="progress-bar">
                                    <div
                                        className="progress"
                                        style={{ width: `${progress}%` }}
                                    ></div>
                                </div>
                            </div>
                        )}
                        <div className="card-content">
                            {!uploadedFile ? (
                                <div className="upload-area">
                                    <input
                                        type="file"
                                        accept="video/*"
                                        onChange={handleUpload}
                                        style={{ display: "none" }}
                                        ref={fileInputRef}
                                    />
                                    <button
                                        onClick={triggerFileInput}
                                        className="upload-button"
                                    >
                                        영상 선택
                                    </button>
                                    <p className="upload-info">
                                        지원 형식: MP4, MOV, AVI (최대 100MB)
                                    </p>
                                </div>
                            ) : (
                                <>
                                    <div className="uploaded-file">
                                        <div className="file-info">
                                            <CheckCircle className="icon" />
                                            <span className="file-name">
                                                {uploadedFile.name.length > 15
                                                    ? uploadedFile.name.slice(
                                                          0,
                                                          12
                                                      ) + "..."
                                                    : uploadedFile.name}
                                            </span>
                                        </div>
                                        <span className="file-size">
                                            {uploadedFile.size}
                                        </span>
                                        <button
                                            onClick={resetUpload}
                                            className="reupload-button"
                                        >
                                            <RefreshCw className="icon" />
                                            다시 업로드
                                        </button>
                                    </div>
                                    {videoSrc && (
                                        <div className="video-preview">
                                            <video
                                                src={videoSrc}
                                                controls
                                                width="100%"
                                            />
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                    {/* 촬영 팁 카드 */}
                    <div className="card">
                        <div className="card-header">
                            <h2 className="card-title">
                                <Info className="icon" />
                                효과적인 골프 스윙 촬영 팁
                            </h2>
                        </div>
                        <div className="card-content">
                            <ul className="tips-list">
                                <li>
                                    카메라를 골퍼의 허리 높이에 맞춰 설치하세요.
                                </li>
                                <li>
                                    정면과 측면 두 가지 각도에서 촬영하면 더
                                    정확한 분석이 가능합니다.
                                </li>
                                <li>
                                    충분한 조명을 확보하여 선명한 영상을
                                    얻으세요.
                                </li>
                                <li>
                                    전체 스윙 동작이 프레임 안에 들어오도록
                                    촬영하세요.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="analysis-section">
                    <div className="card">
                        <div className="card-header">
                            <h2 className="card-title">AI 분석 결과</h2>
                            {audioBlob && (
                                <div className="audio-player">
                                    <button
                                        onClick={playAudio}
                                        className="play-audio-button"
                                    >
                                        <Volume2 className="icon" />
                                        음성으로 듣기
                                    </button>
                                    <audio ref={audioRef} />
                                </div>
                            )}
                        </div>
                        <div className="card-content">
                            <div className="tabs">
                                <button
                                    className={`tab-button ${
                                        !showHistory ? "active" : ""
                                    }`}
                                    onClick={() => setShowHistory(false)}
                                >
                                    현재 분석
                                </button>
                                <button
                                    className={`tab-button ${
                                        showHistory ? "active" : ""
                                    }`}
                                    onClick={() => setShowHistory(true)}
                                >
                                    분석 기록
                                </button>
                            </div>
                            <div className="tab-content">
                                {showHistory ? (
                                    renderHistory()
                                ) : analysisComplete ? (
                                    renderResult()
                                ) : (
                                    <div className="no-analysis">
                                        <Play className="icon" />
                                        <p>
                                            영상이나 이미지를 업로드하면 AI 분석
                                            결과가 여기에 표시됩니다.
                                        </p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <footer className="footer">
                <div className="container">
                    <div className="footer-content">
                        <p className="company-name">
                            <strong>@주식회사 (주)상상력집단</strong>
                        </p>
                        <p className="footer-info">
                            <span>
                                <strong>대표</strong> 송진혁
                            </span>
                            <span>
                                <strong>주소</strong> 서울시 서초구 양재동
                                270-8, 4F
                            </span>
                            <span>
                                <strong>부설연구소</strong> 서울시 성동구
                                아차산로 107, 4F
                            </span>
                        </p>
                        <p className="footer-info">
                            <span>
                                <strong>연락처</strong> 1644-2018
                            </span>
                            <span>
                                <strong>FAX</strong> 031-258-3515
                            </span>
                            <span>
                                <strong>E-mail</strong>{" "}
                                artbunny@illusionists.co.kr
                            </span>
                        </p>
                        <p className="footer-info">
                            <span>
                                <strong>사업자등록번호</strong> 170-87-02633
                            </span>
                        </p>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default GolfAIAnalysis;
